import axios from "axios";
import { baseUrl } from "../utils/urls";

const axiosInstance= axios.create({
   baseURL: baseUrl,
    timeout:30000,
    headers:{
        Authorization: window.localStorage.getItem('access_token')
                   ? 'Bearer ' + window.localStorage.getItem('access_token')
                   : null,
               'content-Type': 'application/json',
               accept:'*/*'    
            
    }
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseUrl + '/api/token/refresh/'
		) {
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				// console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/api/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							// console.log(response)
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'Bearer ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							// console.log(err);
						});
				} else {
					// console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '/login';
				}
			} else {
				// console.log('Refresh token not available.');
				window.location.href = '/login';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);



export default axiosInstance